import React from "react"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import Text from "../components/Text"
import { Link } from "../components/Button"
import VOD from "../components/home/VOD"
import Hero from "../components/Hero"
import News from "../components/home/News"
import { Fade } from "react-awesome-reveal"

// Static imports
import { ReactComponent as Garena } from "../images/brands/garena-logo.svg"
import { ReactComponent as ESL } from "../images/brands/esl.svg"
import { ReactComponent as Ubisoft } from "../images/brands/ubisoft-logo.svg"
import { ReactComponent as Riot } from "../images/brands/riot-logo.svg"
import ESI from "../images/brands/esi-logo.png"
import TGC from "../images/brands/tgc-logo.png"
import LPL from "../images/brands/lpl-logo.png"

const Index = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero title="Streamline Media" subtitle="Production and Talent Agency" />
      <Section className="overflow-hidden">
        <Fade
          direction="up"
          duration={500}
          triggerOnce
          delay={100}
          fraction={0.5}
        >
          <div className="md:items-center flex flex-col">
            <Text
              className="xl:mb-12 md:text-center mb-8"
              styleTo="h1"
              tag="h2"
            >
              We Envision <span className="text-purple-800">Your</span>{" "}
              Imagination
            </Text>
            <Text className="md:text-center lg:w-8/12 mb-12" styleTo="p">
              As a dedicated media and talent agency, we present the latest
              Esports events with lead production and distribution. Please
              become part of the fastest-growing producer and distributor and
              speak to us about your next Esport event. We deliver the quality,
              exposure, and the audience you need.
            </Text>
            <div>
              <h3 className="mb-10 text-2xl font-bold text-center">
                Companies we've worked with
              </h3>
              <ul className="md:flex-row md:space-y-4 xl:gap-16 md:gap-4 md:flex-wrap flex flex-col items-center justify-center">
                <li>
                  <Ubisoft className="sm:w-16 lg:w-24 w-20" />
                </li>
                <li>
                  <ESL className="lg:w-32 w-40" />
                </li>
                <li>
                  <Garena className="lg:w-40 w-24" />
                </li>
                <li>
                  <Riot className="lg:w-40 w-24" />
                </li>
                <li>
                  <img src={ESI} alt="ESI Logo" className="logo-img" />
                </li>
                <li>
                  <img src={TGC} alt="TGC Logo" className="logo-img" />
                </li>
                <li>
                  <img src={LPL} alt="LPL Logo" className="logo-img" />
                </li>
              </ul>
            </div>

            <Link url="/about-us" className="lg:mt-24 mt-10">
              About Us
            </Link>
          </div>
        </Fade>
      </Section>

      <Section>
        <div className="lg:gap-32 lg:mb-40 grid gap-12 mb-12 overflow-hidden">
          <Fade
            duration={500}
            triggerOnce
            direction="left"
            delay={100}
            fraction={0.3}
          >
            <News
              title="Bringing The Best In Esports"
              description="Our team is experienced in professionally producing and broadcasting large-scale gaming events. Working with the best talents, we cater to large scale events providing nothing but the best form of high-end production for our partners and clients."
            />
          </Fade>
          <Fade direction="right" duration={500} triggerOnce delay={100}>
            <VOD
              title="The Top Production Company for Your Next Event "
              ltr
              description="Along with Streamline Gaming, we run a variety of live entertaining sports events, keeping you up to date of the best in Esports. From watching live games to main events, check out the most impressive productions we have to offer. "
            />
          </Fade>
        </div>

        <Fade
          direction="up"
          duration={500}
          triggerOnce
          delay={100}
          fraction={0.9}
        >
          <div className="space-y-10">
            <Text
              className="xl:mb-12 md:text-center mb-8"
              styleTo="h1"
              tag="h2"
            >
              Join Our Mission
            </Text>

            <Link url="/contact" className="mx-auto">
              Get In Touch
            </Link>
          </div>
        </Fade>
      </Section>
      <style>
        {`
          .logo-img {
            width: 100%;
            height: auto;
            max-width: 180px;
          }
        `}
      </style>
    </Layout>
  )
}

export default Index
