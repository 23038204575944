import React from "react"

import { useStaticQuery, graphql } from "gatsby"

// Component imports
import Text from "../Text"
import { TwitchPlayer } from "react-twitch-embed"
import YouTube from "react-youtube-embed"

// Static Assets
import { ReactComponent as Arrow } from "../../images/icons/arrow.svg"

interface NewsProps {
  title: string
  ltr?: boolean
  description: string
}

const News: React.FC<NewsProps> = ({ title, ltr, description }) => {
  // Queries
  const data = useStaticQuery(graphql`
    {
      allSanityHome {
        edges {
          node {
            youTube
            videoId
            streamURL
          }
        }
      }
    }
  `)

  const player = data.allSanityHome.edges[0].node

  return (
    <div
      className={`${
        ltr
          ? "flex-col lg:flex-row-reverse lg:space-x-16 xl:space-x-32 lg:space-x-reverse xl:space-x-reverse"
          : "flex-col lg:flex-row lg:space-x-16 xl:space-x-32 "
      } flex lg:items-center flex-col-reverse lg:justify-between`}
    >
      <div className="lg:w-1/2">
        <div>
          <Text tag="h3" styleTo="h2" className="mb-6 font-bold">
            {title}
          </Text>
          <Text className="mb-8">{description}</Text>

          <a
            //href={player.streamURL}
            href="https://twitch.tv/streamlinemedia"
            target="_blank"
            rel="noopener noreferrer"
            className="group focus:outline-none inline-flex items-center font-bold"
          >
            View our Channel
            <Arrow className="group-hover:translate-x-1 group-focus:translate-x-1 w-4 h-4 ml-4 duration-200 ease-out transform" />
          </a>
        </div>
      </div>
      <div className="lg:w-1/2 md:w-1/2 md:mb-16 lg:mb-0 lg:h-96 h-64 mb-10 bg-gray-800">
        {player.youTube ? (
          <YouTube id={player.videoId} />
        ) : (
          <TwitchPlayer
            channel="streamlinemedia"
            autoplay={false}
            width="100%"
            height="100%"
          ></TwitchPlayer>
        )}
      </div>
    </div>
  )
}

export default News
