import React from "react"

// Component imports
import { Link } from "gatsby"
import Text from "../Text"
import Youtube from "react-youtube-embed"

// Static Assets
import { ReactComponent as Arrow } from "../../images/icons/arrow.svg"

interface NewsProps {
  title: string
  ltr?: boolean
  description: string
}

const News: React.FC<NewsProps> = ({ title, ltr, description }) => {
  return (
    <div
      className={`${
        ltr
          ? "flex-col lg:flex-row-reverse lg:space-x-16 xl:space-x-32 lg:space-x-reverse xl:space-x-reverse"
          : "flex-col lg:flex-row lg:space-x-16 xl:space-x-32 "
      } flex lg:items-center flex-col-reverse lg:justify-between`}
    >
      <div className="lg:w-1/2">
        {/* <div className="md:flex-row md:text-lg flex font-bold text-center cursor-pointer">
          <a
            className="md:px-10 hover:text-blue-500 hover:bg-white hover:shadow-lg hover:outline-none px-5 py-2 transition-all duration-200 ease-out bg-purple-800 rounded-lg"
            href="test"
            target="_blank"
            rel="noopen noreferrer"
          >
            Twitter
          </a>
          <Link
            className="md:px-10 px-5 py-2 bg-purple-900 rounded-r-lg"
            to="ssas"
          >
            NEW SIGNING. JUNE 25TH
          </Link>
        </div> */}
        <div>
          <Text tag="h3" styleTo="h2" className="mb-6 font-bold">
            {title}
          </Text>
          <Text className="mb-8">{description}</Text>
          <Link
            to="/contact"
            className="group focus:outline-none inline-flex items-center font-bold"
          >
            Contact Us
            <Arrow className="group-hover:translate-x-1 group-focus:translate-x-1 w-4 h-4 ml-4 duration-200 ease-out transform" />
          </Link>
        </div>
      </div>

      <div className="lg:w-1/2 md:w-1/2 md:mb-16 lg:mb-0 lg:h-96 h-64 mb-10 bg-gray-800">
        <Youtube id={"bQQkRcJGjpo"} />
      </div>
    </div>
  )
}

export default News
